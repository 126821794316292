import React, { Component } from 'react'
import LogoA from '../../../static/images/narative_logo_a.svg'
import Svg from 'react-inlinesvg'
// import anime from 'animejs'

class LogoAnimation extends Component {
  constructor() {
    super()
    this.a = null;
    this.onClick = this.onClick.bind(this)
  }

  onClick() {
/*
    anime({
      duration: 2000,
      easing: 'linear',
      delay: 0,
      targets: '#LogoAnimation2 #SeparatorLine > *',
      keyframes: [
        {translateX: '95px', opacity: 0}
      ],
    });

    anime({
      duration: 2000,
      easing: 'linear',
      delay: 0,
      targets: '#LogoAnimation2 #Mask_right',
      keyframes: [
        // {d: 'm 150.01043,161.01385 h 34.02202 l 23.44457,-25.79687 h -34.31556 z' },
        // {d: 'm 155.11311,161.01385 h 28.91934 l 23.44457,-25.79687 h -29.68535 z' },
        // {d: 'm 161.34972,161.01385 h 22.68273 l 23.44457,-25.79687 h -23.25975 z' },
        // {d: 'm 168.43677,161.01385 h 15.59568 l 23.44457,-25.79687 h -17.11764 z' },
        // {d: 'm 175.14585,161.01385 h 8.8866 l 23.44457,-25.79687 h -8.80217 z' },
        // {d: 'm 180.15403,161.01385 h 3.87842 l 23.44457,-25.79687 h -3.88847 z' },
        {d: 'm 183.46132,161.01385 h 0.57113 l 23.44457,-25.79687 h -0.51031 z' },
      ]
    });

    anime({
      duration: 20000,
      easing: 'linear',
      delay: 0,
      targets: '#LogoAnimation2 #NBlueLeg > *',
      keyframes: [
        // {d: 'm 0,0 -7.263,-8.024 c 0.136,-0.499 0.243,-1.039 0.316,-1.633 0.081,-0.667 0.125,-1.389 0.125,-2.178 v -19.119 c 1.571,-0.262 3.12,-0.393 4.648,-0.393 1.528,0 3.077,0.131 4.649,0.393 v 21.607 c 0,3.441 -0.575,6.173 -1.712,8.208 C 0.533,-0.729 0.273,-0.355 0,0' },
        {d: 'M 0.59430805,-2.0172992 -6.8049473,-10.817097 c 0.136,-0.499 -0.073,0.594 0,0 0.081,-0.667 -0.017053,-0.228903 -0.017053,-1.017903 v -19.119 c 1.571,-0.262 3.12,-0.393 4.648,-0.393 1.528,0 3.077,0.131 4.649,0.393 v 21.607 c 0,3.441 -0.31457,5.2023065 -1.45157,7.2373065 -0.22999974,0.41 0.103365,-0.1035655 -0.16963503,0.2514345' },
        // {d: 'm 1.5318081,-5.0139511 -8.3367554,-9.1011369 c 0.136,-0.499 -0.073,0.141991 0,-0.452009 0.081,-0.667 -0.017053,0.105918 -0.017053,-0.683082 V -30.954 c 1.571,-0.262 3.12,-0.393 4.648,-0.393 1.528,0 3.077,0.131 4.649,0.393 v 21.607 c 0,3.441 -0.028579,3.8388689 -0.028579,3.8388689 0,0 -0.1142689,0.2145149 -0.387269,0.5695149' },
        {d: 'm 2.2361528,-8.896725 -9.0411001,-10.056533 c 0.136,-0.499 -0.073,-0.329624 0,-0.923624 0.081,-0.667 -0.017053,0.217361 -0.017053,-0.571639 V -30.954 c 1.571,-0.262 3.12,-0.393 4.648,-0.393 1.528,0 3.077,0.131 4.649,0.393 v 20.612631 c 0,3.4410001 -0.028579,0.7196283 -0.028579,0.7196283 l -0.032137,0.6168658' },
        {d: 'm 2.2361528,-14.342079 -8.9071715,-9.968322 c 0.136,-0.499 -0.073,-0.329624 0,-0.923624 0.081,-0.667 0.00347,0.217094 -0.017053,-0.571639 L -6.8220003,-30.954 c 1.571,-0.262 3.12,-0.393 4.648,-0.393 1.528,0 3.077,0.131 4.649,0.393 v 15.167277 c 0,3.441 -0.028579,0.719629 -0.028579,0.719629 l -0.032137,0.616865' },
        {d: 'm 2.4101674,-14.340895 -9.2151147,-10.006814 v -0.194421 l -0.017053,-0.165605 V -30.954 c 1.571,-0.262 3.12,-0.393 4.648,-0.393 1.528,0 3.077,0.131 4.649,0.393 v 16.04682 l -0.028579,0.123007 -0.023851,0.235691' },
        {d: 'm 2.4101674,-20.317458 -9.2151147,-9.913601 v -0.194421 l -0.017053,-0.165605 V -30.954 c 1.571,-0.262 3.12,-0.393 4.648,-0.393 1.528,0 3.077,0.131 4.649,0.393 v 10.070257 l -0.028579,0.123007 -0.023851,0.235691' },
        {d: 'm 2.4101674,-25.571041 -4.5415807,-4.660018 v -0.194421 l -0.017053,-0.165605 V -30.954 c 1.57099998,-0.262 -1.553534,-0.393 -0.025534,-0.393 1.528,0 3.077,0.131 4.649,0.393 v 4.816674 l -0.028579,0.123007 -0.023851,0.235691' },
        // {d: 'M 2.4101674,-30.062274 1.879208,-30.231059 V -30.42548 L 1.862155,-30.591085 V -30.954 l -0.025534,-0.393 c 0,0 0.6383648,0.392997 0.6383786,0.393 v 0.325441 l -0.028579,0.123007 -0.023851,0.235691' },
      ]
    });

    // if (this.a == null) {


   /!*   this.a = anime.timeline({
        // direction: 'alternate',
        // loop: true,
        // delay: 0,
        duration: 2000,
        easing: 'linear',
      })

      this.a.add(, 0)


      this.a.add({
        targets: '#LogoAnimation2 #rect4711-3',
        d: [
          // { value: 'm 184.03245,161.01385 2.55134,0.28348 20.89323,-26.08035 -1.98438,0.0945 z' },
          { value: 'm 150.01043,161.01385 h 34.02202 l 23.44457,-25.79687 h -34.31556 z' },
        ],
      }, 0)
    } else {
      this.a.restart();
      // this.a.reverse();
    }
*!/

    /!*anime({
      targets: '#LogoAnimation2 #rect4711-3',
      keyframes: [
        {translateY: -40},
        {translateX: 250},
        {translateY: 40},
        {translateX: 0},
        {translateY: 0}
      ],
      duration: 4000,
    })*!/
*/
  }

  render() {
    return (
      <div className={'container mt-5'}>
        <button onClick={() => {
          this.onClick()
        }}>Toggle animation
        </button>
        <div id={'LogoAnimation2'}>
          <Svg src={LogoA} uniquifyIDs={false}/>
        </div>
      </div>
    )
  }
}

export default LogoAnimation
